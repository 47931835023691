import React, { useContext, useEffect, useState } from 'react';
import './Home.css';
import { AuthContext } from '../../../Context/AuthContext';
import { Chart } from 'primereact/chart';
import TitleBar from '../../../components/TitleBar/TitleBar';
import superApi from '../../../Api/Api';
import Loader from '../../../HOC/Loader/Loader';
import Top10 from '../../../components/Top10/Top10';
import Leaderboards from '../Leaderboards/Leaderboards'
import '../../../../node_modules/primeflex/primeflex.css';
import { characterSelection, levelSpriteMap } from '../../../helpers/sprites';
import SwipeableViews from 'react-swipeable-views';
const Home = (props) => {

    const [polarPoints, setPolarPoints] = useState(null);
    const [polarData, setPolarData] = useState(null)
    // const [topPeople, setTopPeople] = useState([]);
    // const [leaderboardWeek, setLeaderboardWeek] = useState(0)
    // const [characterSet, setCharacterSet] = useState('teal')
    const [characterIndex, setCharacterIndex] = useState(0)
    const [spritex, setSpritex] = useState(0)
    const [slideIndex, setSlideIndex] = useState(1)
    const polarOptions = {
        title: {
            display: true,
            text: 'Your Wellbeing',
            fontSize: 24
        },
        legend: {
            position: 'bottom'
        }
    };




    const buildPolar = (points) => {
        if (polarData == null) {
            return []
        }
        const polarColors = Object.keys(auth.categories).map(cat => {
            return auth.categories[cat].color
        })
        const polarLabels = Object.keys(auth.categories).map(cat => {
            return auth.categories[cat].slogan.toUpperCase()
        })
        let data = {
            datasets: [{
                data: polarData,
                backgroundColor: polarColors,
                label: 'My Points'
            }],
            labels: polarLabels
        }

        return data;
    }




    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        if (auth.profile) {
            console.log(auth.profile.preferences?.character_index)
            console.log(levelSpriteMap[auth.profile.achievement?.level])
            setSpritex(levelSpriteMap[auth.profile.achievement?.level])
            auth.profile.preferences?.character_index && setCharacterIndex(auth.profile.preferences?.character_index)
        }
    }, [auth.profile])

    useEffect(() => {
        if (auth.points.length > 0) {

            let _polarPoints = {}
            auth.points.forEach(point => {
                if (_polarPoints[point.category] === undefined) {
                    _polarPoints[point.category] = 0
                }
                _polarPoints[point.category] += point.points
            })
            setPolarPoints(_polarPoints)

        }
        if (auth.categories) {
            if (polarPoints) {

                let _polarData = Object.keys(auth.categories).map(cat => {
                    return polarPoints[cat]
                })

                setPolarData(_polarData)
                setIsLoading(false)
            }

        }



    }, [auth.points, auth.categories, isLoading])


    let points = 0;
    let myTeamPoints = 0;
    let teamMemberLength = 1;

    if (auth.points && auth.points.length > 0) {
        points = auth.points.map(point => point.points).reduce((a, b) => a + b, 0)
        myTeamPoints = auth.points[0].teampoints
        // console.log(auth.points)
    }

    let myPointsBadge = null

    switch (points < 1000) {
        case true:
            myPointsBadge = <h1>{points}</h1>
            break;
        case false:
            myPointsBadge = <h2>{points}</h2>
            break;

    }


    if (auth.myTeam !== null) {

        auth.myTeam.members.map((member) => {

            return null;
        })

        teamMemberLength = auth.myTeam.members.length;

    }

    const CATEGORY_BUTTONS = auth.categories ? Object.keys(auth.categories).map((cat, i) => {
        return (
            <div key={i} className="category-button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'uppercase', backgroundColor: auth.categories[cat].color, color: "white", width: '40%', margin: 10 }}>{auth.categories[cat].name.replace('-', ' ')}</div>
        )
    }) : null

    const changeSlide = () => {
        const index_count = 2
        if (slideIndex >= index_count - 1) {
            setSlideIndex(index_count - 2)
        }
        else {
            setSlideIndex(prev => prev + 1)
        }
    }

    const getCharacter = () => {

        return {
            width: 340,
            height: 340,
            background: `url('/assets/characters/${characterSelection[characterIndex]?.filename}.png')`,
            backgroundPositionX: `${spritex}px`,
            backgroundPositionY: 0,
            backgroundRepeat: 'no-repeat',

        }

    }

    if (auth.profile.email) {

        return (
            <Loader text={props.loadingText} loading={isLoading}>
                <TitleBar {...props} share={props.share} items={[]} />
                <div className="Home">


                    <div className="flex flex-column top-panel max-w-screen">
                        <SwipeableViews
                            enableMouseEvents
                            resistance
                            index={slideIndex}

                        >
                            <div className="sprite-container max-w-screen justify-content-center flex align-items-center">
                                <div className={("absolute left-0 ml-2 text-2xl text-200 border border-round border-200 p-1 pi pi-chevron-left")}></div>
                                <div className="bg-cover" style={getCharacter()}>
                                </div>

                                <div onClick={changeSlide} className={("absolute right-0 mr-2 text-2xl text-600 border border-round p-1 pi pi-chevron-right")}></div>
                            </div>
                            <div className="main flex flex-row relative">
                                <div onClick={changeSlide} className={("absolute left-0 ml-2 text-2xl text-600 border border-round p-1 pi pi-chevron-left")}></div>
                                <div className=''>


                                        <div className="polar">{auth.categories ? <Chart height="320px" width="320px" type="polarArea" options={polarOptions} data={buildPolar(polarPoints)} /> : null}</div>
                                </div>
                                    {CATEGORY_BUTTONS}
                                <div className={("absolute right-0 mr-2 bg-ping-300 text-2xl text-200 border border-round border-200 p-1 pi pi-chevron-right")}></div>
                                {/* {auth.leaderboard.leaderboard !== undefined ? <Top10 week={leaderboardWeek} leaderboard={auth.leaderboard}/> : null} */}
                            </div>
                        </SwipeableViews>
                        <div className='flex flex-row justify-content-between'>

                            <div className="col top-panel-left">

                                {myPointsBadge}
                                <p>You</p>
                            </div>
                            <div className="col top-panel-right">

                                <h1>{myTeamPoints}</h1>
                                <p>Team</p>
                            </div>
                        </div>
                        <div className="leaderboard">

                            <Leaderboards setIsLoading={setIsLoading} />
                        </div>
                    </div>
                </div>
            </Loader>
        );
    } else {
        return <Loader text={props.loadingText} loading={isLoading} />
    }

}

export default Home;