import React, { useContext, useState, useRef, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import './Login.css';
import Formsy from 'formsy-react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormsyElement from '../../../HOC/FormsyElement/FormsyElement';
import { Password } from 'primereact/password';
import TitleBar from '../../../components/TitleBar/TitleBar';
import superApi from '../../../Api/Api';
import { login, logout } from '../../../services/authService'; //FusionAuth token
import { AuthContext } from '../../../Context/AuthContext';
import AnimatedLogo from '../../../components/Overlay/AnimatedLogoNew/AnimatedLogo';
import Forgot from './Forgot/Forgot';
import ChangePassword from '../../Layout/Profile/ChangePassword/ChangePassword';
import newLogo from '../../../assets/images/newLogo.png';
import { Messages } from 'primereact/messages';
import * as Sentry from '@sentry/browser'
const Login = (props) => {
    const auth = useContext(AuthContext);
    const [showForgot, setShowForgot] = useState(false)
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [forgotEmailValue, setForgotEmailValue] = useState('')
    const [forgotFormType, setForgotFormType] = useState('forgot')
    const [canSubmit, setCanSubmit] = useState(true)

    const loginError = useRef()

    const loginSubmit = (model) => {
        window.localStorage.removeItem('authBody')
        window.localStorage.removeItem('twbcToken')
        superApi.post('/login', {}, { auth: { username: model.username, password: model.password } })
            .then((response) => {
                login(model.username, model.password)
                    .then(() => {
                        // window.location.reload();
                        auth.setAuthBody(response.data.token)
                        auth.setAuthenticated(true);
                        Sentry.captureMessage("User "+model.username+ " logged in successfully")
                    })
                    .catch((e) => loginError.current.show({ severity: 'error', detail: e }))

            })
            .catch((error) => {
                // Login Failed
                window.localStorage.setItem('authenticated', false);
                window.localStorage.setItem('authBody', null);
                auth.setAuthenticated(false);
                loginError.current.show({ severity: 'error', detail: 'Login Failed' })
                Sentry.captureMessage("Login failed for "+model.username+ ", password: "+model.password)
            });

    }

    const iforgotSubmitHandler = (type) => {
        setCanSubmit(false)
        superApi.post('/iforgot', { email: forgotEmailValue, type: type })
            .then((response) => {

                setCanSubmit(true)
                if ('token' in response.data) {
                    auth.setAuthBody(response.data.token)
                    // auth.setAuthenticated(true);
                    setShowForgot(false)
                    setShowChangePassword(true)
                }
                if (type == 'forgot' && response.status == 200) {
                    setForgotFormType('verify')
                    setForgotEmailValue('')
                }
            })
            .catch((error) => {
                setCanSubmit(true)
                document.getElementsByClassName('emailWrapper')[0].classList.add("shake")
                window.setTimeout(() => document.getElementsByClassName('emailWrapper')[0].classList.remove("shake"), 1000)
            })
    }

    const setForgotFormTypeHandler = (type) => {
        setForgotFormType(type)
    }

    const showForgotPasswordHandler = () => {
        setShowForgot(true)
    }

    const hideForgotPasswordHandler = () => {
        setShowForgot(false)
    }

    const showChangePasswordHandler = () => {
        setShowChangePassword(true)
    }

    const hideChangePasswordHandler = () => {
        setShowChangePassword(false)
        window.location.reload()
    }

    useEffect(() => {
        props.auth.setUserValueHandler("");
        props.auth.setPassValueHandler("");
    },[])

    if (!auth.authenticated) {
        return (
            <React.Fragment>

                <Messages className="mx-4 w-full" ref={loginError}></Messages>
                <div className="Login" style={{ display: 'flex', flex: 1 }}>
                    <div className="logo-wrapper" style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        {/* <AnimatedLogo /> */}
                        <img style={{ width: '60vw' }} src={newLogo} />
                    </div>
                    <div className="form-wrapper">
                        <Formsy onValidSubmit={(model) => loginSubmit(model)}
                            onValid={() => props.auth.enableButton()}
                            onInvalid={() => props.auth.disableButton()}>

                            <FormsyElement
                                name="username"
                                value={props.auth.userValue}
                                // validations={}
                                validationError="Please enter a valid Email"
                            // required>
                            >
                                <InputText
                                    value={props.auth.userValue}
                                    onChange={(e) => props.auth.setUserValueHandler(e.target.value)}
                                    placeholder="email@example.com"
                                    autoCapitalize="none"
                                />
                            </FormsyElement>

                            <FormsyElement
                                name="password"
                                value={props.auth.passValue}
                                // validations={}
                                validationError="Password too short"
                            // required
                            >
                                <Password
                                    // name="username"
                                    value={props.auth.passValue}
                                    onChange={(e) => props.auth.setPassValueHandler(e.target.value)}
                                    placeholder="password"
                                    feedback={false}
                                />
                            </FormsyElement>

                            <Button className="p-button-success" label="login" type="submit" disabled={!props.auth.canSubmit} />
                            <div className="alt-actions">
                                <div className="register p-button-info"><a href="/register">Sign Up</a></div>
                                <div className="iforgot p-button-info"><a onClick={() => showForgotPasswordHandler()}>Forgot Password</a></div>
                            </div>
                        </Formsy>
                    </div>
                </div>
                <Forgot close={hideForgotPasswordHandler}
                    show={showForgot}
                    forgotSubmit={iforgotSubmitHandler}
                    emailValue={forgotEmailValue}
                    setEmailValue={setForgotEmailValue}
                    type={forgotFormType}
                    disable={!canSubmit}
                    setFormType={setForgotFormTypeHandler} />
                <ChangePassword 
                    show={showChangePassword}
                    close={hideChangePasswordHandler}
                    oldPassword={forgotEmailValue} />

            </React.Fragment>
        );
    } else return <Redirect to="/" />
}

export default Login;