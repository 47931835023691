import React,{useEffect} from 'react';
import { Redirect } from 'react-router-dom';

const Aux = (props) => {
    useEffect(() => {
        if(window.localStorage.getItem('expired')) {
            props.setAuthenticated(false);
        }
    })

    if(props.authenticated) {
        return props.children;
    } else {
        return <Redirect to="/login" />
    }
    
}
export default Aux;