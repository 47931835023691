import React, { useState, useEffect, useRef } from 'react'

import { Dialog } from 'primereact/dialog';
import { getActivityByMid } from '../../../../services/hasuraRest';
import './AchievementDialog.css';
import { Button } from 'primereact/button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';

import { InputNumber } from 'primereact/inputnumber'

import { Checkbox } from 'primereact/checkbox';
import { Messages } from 'primereact/messages';
import { getToken, logout } from '../../../../services/authService';
export default function AchievementDialogFull(props) {
    const [hasuraActivity, setHasuraActivity] = useState(null)
    const [checked, setChecked] = useState(false)
    const [numberValue, setNumberValue] = useState(1)
    const textInput = useRef()
    const errorMessage = useRef()
    useEffect(() => {
        if (props.activity) {

            getActivityByMid(props.activity._id)
                .then(response => {

                    setHasuraActivity(response.data.activity[0])
                })
                .catch(error => {
                    const token = getToken()
                    if(token === null) {
                        logout()
                    }
                    props.setShowActivityDialog(false);
                    errorMessage.current.show({ severity: 'error', detail: 'Error fetching data' })
                })
        }
    }, [props.activity])

    useEffect(() => {
    }, [hasuraActivity])


    const detectEnter = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            props.submitAchievementHandler(e, props.today);
        }

    }

    const getSubmittedValue = () => {
        switch (hasuraActivity?.input_type) {
            case 'bool':
                return checked
            case 'text':
                return null
            case 'number':
                return numberValue
        }
    }

    const footer = (
        <div className="footer">

            <Button onClick={(e) => props.setShowActivityDialog(false)}
                style={{ marginLeft: '5px' }}
                label="cancel"
                icon="pi pi-times"
                className="p-button-danger"
            />
            {/* <Button onClick={(e) => props.showHistory(props.currentActivity)}
                style={{ marginLeft: '5px' }}
                label="edit"
                icon="pi pi-pencil"
                className="p-button-warning"
            /> */}
            <Button onClick={(e) => props.submitAchievementHandler(e, props.today, getSubmittedValue(), hasuraActivity.input_type)}
                style={{ marginLeft: '5px' }}
                label="save"
                icon="pi pi-check"
                className="p-button-info"
            />

        </div>

    )

    const photoHook = (e) => {
        if (props.type == 'add' && props.enablePhotoUploads) {
            props.setShowActivityDialog(false)
            confirmAlert({
                title: 'Photo upload',
                message: 'Would you like to upload a photo?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => document.getElementById("photo-select").click()
                    },
                    {
                        label: 'No Thanks',
                        onClick: () => props.submitAchievementHandler(e, props.today)
                    }
                ]
            })
        } else {
            // Validate

            props.submitAchievementHandler(e, props.today)
        }

    }

    const handleCheck = () => setChecked(!checked)

    const inputType = () => {
        switch (hasuraActivity?.input_type) {
            case 'bool':
                return (
                    <Checkbox checked={checked} className="w-full h-auto"
                        style={{

                        }}
                        onChange={handleCheck} />
                )
            case 'text':
                return (
                    <div className='flex align-items-start flex-column w-full mb-3'>
                        <h4 className='text-indigo-600 text-xl'>What did you do?</h4>
                        <input className='h-2rem' type="text" autoFocus="autoFocus" ref={textInput} value={props.achievementValue} onKeyPress={detectEnter} onChange={(e) => props.setValue(e.target.value)} />
                    </div>
                )
            case 'number':
                return (
                    <div className='flex align-items-start flex-column w-full mb-3'>
                        <InputNumber inputId="vertical" value={numberValue} onValueChange={(e) => setNumberValue(e.value)} mode="decimal" buttonLayout="vertical" style={{ width: '4rem' }}
                            decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                )
        }
    }

    return (
        <>
            <Messages className="mx-4 w-full" ref={errorMessage}></Messages>
            <Dialog
                className=' AchievementDialog w-full sm:w-11 md:w-9 lg:w-7 xl:w-4'

                header={hasuraActivity?.title}
                visible={props.showActivityDialog}
                footer={footer}
                closeOnEscape
                dismissableMask
                closable
                showHeader={false}
                onHide={() => props.setShowActivityDialog(false)}
            >

                <div className="flex flex-column align-items-start">
                    <div className='h-12rem w-full mb-1' style={{
                        backgroundImage: `url('${hasuraActivity?.header_image !== '#' ? hasuraActivity?.header_image : 'http://placekitten.com/600/400'}')`,
                        backgroundSize: 'cover'
                    }}>

                    </div>
                    <div className="px-2">
                        <div className='flex align-items-start flex-column'>
                            <h4 className='text-indigo-600 text-xl'>Did you know...</h4>
                            <p className='text-left'>{hasuraActivity?.fact}</p>
                        </div>
                        <div className='flex align-items-start flex-column'>
                            <h4 className='text-indigo-600 text-xl'>{hasuraActivity?.title}</h4>
                            <p className='text-left'>{hasuraActivity?.description}</p>
                        </div>

                        {inputType()}
                        <div className="formError">{props.errors.achievement}</div>
                        <div className='flex flex-row justify-content-between w-full'>
                            <div className='flex flex-column h-auto'>
                                {hasuraActivity?.points > 0 && <div className='text-indigo-600'>{`${hasuraActivity?.points} ${hasuraActivity?.points === 1 ? 'POINT' : 'POINTS'}`}</div>}



                            </div>
                            <div className='pi pi-camera hidden'></div>

                        </div>
                    </div>

                </div>
            </Dialog>
        </>
    )
}
