import React, { useEffect, useState } from 'react';
import superApi from '../Api/Api';

export const AuthContext = React.createContext();

export default (props) => {
    const prevAuth = window.localStorage.getItem('auth') || false;
    const prevAuthBody = window.localStorage.getItem('authBody') || null;
    const [authenticated, setAuthenticated] = useState(prevAuth);
    const [authBody, setAuthBody] = useState(prevAuthBody);
    const [profile, setProfile] = useState({});
    const [myTeam, setMyTeam] = useState(null);
    const [activities, setActivities] = useState(null);
    const [teams, setTeams] = useState([]);
    const [categories, setCategories] = useState(null);
    const [points, setPoints] = useState([])
    const [leaderboard, setLeaderboard] = useState({})
    
    const logoutHandler = () => {
        setAuthBody(null);
        setAuthenticated(false);
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('authBody');
    }


    useEffect(() => {
        if (authenticated) {
            superApi.get('/challenges')
                .then((response) => {
                    setActivities(response.data.tasks);
                    return response.data.tasks
                }).then(tasks => {

                    superApi.get('/category/').then((response) => {
                        let _obj = {}

                        response.data._items.map((item) => {
                            if (!(item.name in _obj)) {
                                _obj[item.name] = {}
                            }
                            _obj[item.name]['name'] = item.name
                            _obj[item.name]['slogan'] = item.slogan
                            _obj[item.name]['color'] = item.color

                        })

                        return [tasks, _obj]

                    }).then(response => {

                        if (response[0]) {
                            let filteredCategories = []
                            response[0].map((a) => {
                                //  achievablePoints+=a.points;
                                filteredCategories.push(a.category)
                            })
                            const _categories = Object.keys(response[1]).filter(key => {
                                return filteredCategories.includes(key)
                            })
                                .reduce((obj, key) => {
                                    obj[key] = response[1][key];
                                    return obj;
                                }, {});
                            setCategories(_categories)

                        }
                    })
                })
        }

    }, [authenticated]);

    useEffect(() => {
        if (authenticated) {
            getMyTeam();
            getMyPoints();
        }
        
        


    }, [authenticated]);

    const getMyPoints = () => {
        superApi.get('mypoints').then(response => {

            setPoints(response.data.points)
        })
    }
    const getMyTeam = () => {
        superApi.get('myteam')
            .then((response) => {
                // Add team to profile state
                setMyTeam(response.data.team);
                if (response.data.team == null) {
                    // Get all teams
                    superApi.get('teams')
                        .then((response) => {

                            setTeams(response.data.teams);

                        })
                        .catch((error) => {

                        });
                }
            })
            .catch((error) => {

            });
    }

    const calculatePoints = (profile) => {
        let approved_points = 0;
        let index = 0;
        profile.achievements.map((task) => {
            approved_points += task.bonus || 0
            task.days.map((day) => {
                approved_points += day.points;
                return null;
            })
            profile.achievements[index]['approved_points'] = approved_points;
            approved_points = 0;
            index += 1;

            return null;

        })



        return profile;
    }
    const getProfile = () => {
        if (authenticated) {
            superApi.get('/myprofile')
                .then((response) => {

                    setProfile(calculatePoints(response.data.profile.profile));

                })
                .catch((error) => {

                });
        }


        return null;
    }

    useEffect(() => {

        if (window.localStorage.getItem('expired')) {
            setAuthenticated(false);
            window.localStorage.removeItem('expired');

        }


        (authenticated) ? window.localStorage.setItem('auth', authenticated) : window.localStorage.removeItem('auth');
        window.localStorage.setItem('authBody', authBody);
        if (authenticated) {
            getProfile();
        }

    }, [authenticated, authBody]);

    const defaultContext = {
        authenticated,
        setAuthenticated,
        authBody,
        setAuthBody,
        logoutHandler,
        profile,
        getProfile,
        myTeam,
        setMyTeam,
        activities,
        setActivities,
        teams,
        getMyTeam,
        calculatePoints,
        categories,
        setCategories,
        points,
        getMyPoints,
        leaderboard,
        setLeaderboard
    };

    return (
        <AuthContext.Provider value={defaultContext}>
            {props.children}
        </AuthContext.Provider>
    );
}