import React, { useEffect, useState } from 'react';
import './RegisterRedirect.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


const RegisterRedirect = (props) => {
    const [slugFieldValue, setSlugFieldValue] = useState(null)
    useEffect(() => {
        console.log(slugFieldValue);
    }, [slugFieldValue])

    return (
        <div className="RegisterRedirect">
            <InputText id="slug" name="slug" placeholder="Enter Slug" onChange={e => setSlugFieldValue(e.target.value.toLowerCase())}/>
            <Button label="Go" className="p-button-success" onClick={() => window.location.href = `${window.location.href}/${slugFieldValue}`} />
        </div>
    );
}

export default RegisterRedirect;