import React, { useEffect, useContext, useState, useRef } from 'react';
import './Profile.css';
import superApi from '../../../Api/Api';
import Formsy from 'formsy-react';
import { File } from 'formsy-react-components';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import FormsyElement from '../../../HOC/FormsyElement/FormsyElement';
import { AuthContext } from '../../../Context/AuthContext';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Loader from '../../../HOC/Loader/Loader';
import { Redirect } from 'react-router-dom';
import ChangePassword from './ChangePassword/ChangePassword';

const Profile = (props) => {


    const auth = useContext(AuthContext);
    const profile = auth.profile;
    const [canSubmit, setCanSubmit] = useState(false)
    const [firstNameValue, setFirstNameValue] = useState(profile.first_name || '')
    const [lastNameValue, setLastNameValue] = useState(profile.last_name || '')
    const [emailValue, setEmailValue] = useState(profile.email || '')
    const [imageUploading, setImageUploading] = useState(false);
    const [oldPasswordValue, setOldPasswordValue] = useState('')
    const [newPasswordValue, setNewPasswordValue] = useState('')
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [regionValue, setRegionValue] = useState('');
    const [regionOptions, setRegionOptions] = useState([]);

    useEffect(() => {


    }, [auth.profile])

    const growlRef = useRef(null);
    const showChangePasswordHandler = () => {
        setShowChangePassword(true)
    }

    const hideChangePasswordHandler = () => {
        setShowChangePassword(false)
    }
    const changePasswordSubmitHandler = () => {
        superApi.post('/change', { password: oldPasswordValue, new_password: newPasswordValue })
            .then((response) => {
                // receive status_code
                // display instructions
                // change form to verification code
                // have reset button to try again
                console.log(response.status)
                if (response.status == 200) {
                    hideChangePasswordHandler()
                } else if (response.status == 403) {
                    window.alert('Wrong verification code or password')
                }
            })
            .catch((response) => window.alert('Wrong verification code or password'))
    }
    const profileSubmit = (model) => {
        console.log(model);



        // TODO: create avatar directory if doesn't exist
        superApi.post('/myprofile', model)
            .then((response) => {
                growlRef.current.show({ severity: 'success', summary: 'Success', detail: 'Profile Updated' });

            })
            .catch((error) => {
                growlRef.current.show({ severity: 'danger', summary: 'Fail', detail: '' });
            })


    }

    const disableButton = () => {
        setCanSubmit(false);
    };
    const enableButton = () => {
        setCanSubmit(true);
    };

    const setFirstNameHandler = (value) => {
        setFirstNameValue(value);
    }
    const setLastNameHandler = (value) => {
        setLastNameValue(value);
    }
    const setEmailHandler = (value) => {
        setEmailValue(value);
    }

    const setRegionHandler = (value) => {
        setRegionValue(value);
    }

    const uploadAvatarHandler = (file) => {
        setImageUploading(true)
        const formData = new FormData();
        formData.append("avatar", file);

        // reader.onload = (e) => {

        superApi.post('/profile/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(
                (response) => {
                    const thumb = document.getElementsByClassName("avatar-thumb")[0];
                    const img = document.createElement("img");
                    img.src = window.URL.createObjectURL(file)
                    thumb.innerHTML = "";
                    thumb.append(img);
                    setImageUploading(false)
                }
            )
            .catch((error) => {
                growlRef.current.show({ severity: 'error', summary: 'Fail', detail: 'Maybe unsupported file type' });
                setImageUploading(false);
            })
        // }

    }

    useEffect(() => {
        auth.getProfile();
    }, [])

    useEffect(() => {
        setFirstNameHandler(auth.profile.first_name)
        setLastNameHandler(auth.profile.last_name);
        setEmailHandler(auth.profile.email);
        console.log(auth.profile);
        if (auth.profile.region_options != undefined) {

            const _regionOptions = auth.profile.region_options.map(option => {
                return {
                    dataKey: option, label: option, value: option
                }
            })
            setRegionOptions(_regionOptions);
        }

    }, [auth.profile])

    const showAvatar = () => {
        if (auth.profile.avatar) {
            return (
                <img src={superApi.defaults.baseURL + "avatar/" + auth.profile.avatar} />
            )
        } else {
            return (
                <i className="material-icons">{props.captain ? "perm_identity" : "person"}</i>
            )
        }
    }

    const items = [{ label: 'My History', icon: 'pi pi-fw pi-power-off', command: () => { window.location = "profile/history" } },
    { label: 'Change Password', icon: 'pi pi-fw pi-power-off', command: () => showChangePasswordHandler() }]

    if (auth.profile) {
        return (
            <Loader loading={imageUploading} text="Saving...">
                <TitleBar title="My Details" items={items} from="profile" />
                <div className="Profile">

                    <div className="fileUpload">

                        <a onClick={() => document.getElementById("avatar-select").click()}><span className="team-avatar avatar-thumb">{showAvatar()}</span></a>
                        <input name="avatar" id="avatar-select" type="file" style={{ display: 'none' }} onChange={(e) => uploadAvatarHandler(e.target.files[0])} />

                    </div>
                    {(auth.profile.first_name && auth.profile.last_name) ? <span className="fullname">{auth.profile.first_name} {auth.profile.last_name}</span> : <span className="fullname">Who are you?</span>}
                    <Formsy onValidSubmit={(model) => profileSubmit(model)}
                        onValid={() => enableButton()}
                        onInvalid={() => disableButton()}
                        encType="multipart/form-data" >

                        <FormsyElement
                            name="first_name"
                            value={firstNameValue}
                            validations="minLength:2"
                            validationError="Too Short"
                            required>
                            <InputText
                                // name="username"
                                value={firstNameValue}
                                onChange={(e) => setFirstNameHandler(e.target.value)}
                                placeholder="First Name"

                            />
                        </FormsyElement>

                        <FormsyElement
                            name="last_name"
                            value={lastNameValue}
                            validations="minLength:2"
                            validationError="Too Short"
                            required>
                            <InputText
                                // name="username"
                                value={lastNameValue}
                                onChange={(e) => setLastNameHandler(e.target.value)}
                                placeholder="Last Name"

                            />
                        </FormsyElement>

                        <FormsyElement
                            name="email"
                            value={emailValue}
                            validations="isEmail"
                            validationError="Email not valid yet"
                            required>

                            <InputText
                                // name="username"
                                value={emailValue}
                                onChange={(e) => setEmailHandler(e.target.value)}
                                placeholder="Email Address"

                            />
                        </FormsyElement>

                            <FormsyElement
                                name="region"
                                value={regionValue}

                                >
                                <Dropdown
                                style={{'width': '90%', height: '50px'}}
                                    // name="username"
                                    
                                    placeholder="Select Region"
                                    value={regionValue}
                                    onChange={(e) => setRegionHandler(e.target.value)}
                                    options={regionOptions}

                                />
                            </FormsyElement>

                        <Button className="p-button-success" label="Submit" type="submit" disabled={!canSubmit} />
                    </Formsy>
                    <Toast ref={(el) => growlRef.current = el} />

                </div>
                <ChangePassword close={hideChangePasswordHandler}
                    show={showChangePassword}
                    close={hideChangePasswordHandler} />
            </Loader>
        );
    } else {
        return <p>loading..</p>
    }

}

export default Profile;