import React from 'react';
import './Loader.css';

const Loader = (props) => {
    if(window.localStorage.getItem('expired')) {
        props.setAuthenticated(false);
    }
    return ( 
        <React.Fragment>
            {props.loading ? <div className="Loader"><div className="overlay"></div><span className="text">{props.text}</span></div> : null}
            {props.children}
        </React.Fragment>
     );
}
 
export default Loader;