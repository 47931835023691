import {withFormsy} from 'formsy-react';
import React from 'react';

const FormsyElement = (props) => {
    const errorMessage = props.getErrorMessage();
    return (
        <React.Fragment>
        {props.children}
        <p style={{fontSize: '10px', color: 'red'}}>{errorMessage}</p>
        </React.Fragment>
    )
}


export default withFormsy(FormsyElement);