import React from 'react';
import './TeamsList.css';
import TitleBar from '../../../../components/TitleBar/TitleBar';

const TeamsList = (props) => {
    let displayClass = 'hide';

    if (props.show) {

        displayClass = 'show'
    }
    // const items = [{label: 'Leave team', icon: 'pi pi-fw pi-power-off',command:() => document.querySelector('.TeamsList').style.transform = "translate(-100%)" }]
    return ( 
        <React.Fragment>
        <TitleBar />
        <div  className={"TeamsList " + displayClass}>
        <div className="title">
            
            <button 
                    className="leave"
                    onClick={() => document.querySelector('.TeamsList').classList.add('hide')}
                    >
                    <i className="material-icons">
                    keyboard_arrow_down
                    </i>
                </button>
                Join a team
        </div>
        {/* <TitleBar items={items} /> */}
        <div className="list">
            {/* <ul style={{listStyle: 'none', padding: '20px'}}> */}
            {props.teams}
            {/* </ul> */}
            </div>
        </div>
        </React.Fragment>
     );
}
 
export default TeamsList;