import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './Share.css';
import superApi from '../../Api/Api';

const Share = (props) => {
    const [inputValue, setInputValue] = useState("")
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState(null)

    const send = () => {
        superApi.post('/share', {
            email: inputValue,
            sender: props.profile.first_name || "Someone",
            slug: props.profile.slug
        }).then(response => {
            setMessage(response.data.message)
            setSuccess(true)
        }).catch(error => {

            setMessage(error.response.data.message)
        })
    }

    const hideHandler = () => {
        props.hide()
        setSuccess(false)
        setMessage(null)
    }

    if (success) {
        return (

            <Dialog className="Share" style={{ width: '90%' }} header="Share with friends and family" modal onHide={props.hide} visible={props.show}>
                <span className="message">{message}</span>
                <div className="buttonWrapper">
                    <Button label="ok" onClick={hideHandler} className="p-button-info" />
                </div>
            </Dialog>

        )
    }
    return (
        <Dialog className="Share" style={{ width: '90%' }} header="Share with friends and family" modal onHide={props.hide} visible={props.show}>
            <InputText autoCapitalize="none" id="share" name="slug" value={inputValue} placeholder="gran@hotmail.com" onChange={e => setInputValue(e.target.value)} />
            <span className="message">{message}</span>
            <div className="buttonWrapper">
                <Button label="send" onClick={() => send()} className="p-button-info" />
                <Button label="cancel" onClick={hideHandler} className="p-button-danger" />
            </div>
        </Dialog>
    );
}

export default Share;