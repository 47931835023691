import React from 'react';
import './CreateTeamForm.css';
import Formsy from 'formsy-react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import FormsyElement from '../../../../HOC/FormsyElement/FormsyElement';
import {Dialog} from 'primereact/dialog';

const CreateTeamForm = (props) => {
    const footer = (
        <div className="footer">

            <Button onClick={(e) => props.hide(false)} 
                    style={{marginLeft: '5px'}} 
                    label="cancel"
                    icon="pi pi-times"
                    className="p-button-danger"
                    />
            <Button  
                    style={{marginLeft: '5px'}} 
                    label="save"
                    icon="pi pi-check"
                    className="p-button-info"
                    disabled={!props.canSubmit}
                    type="submit"
                    />
            
        </div>

    )
    return ( 
        <Formsy onValidSubmit={(model) => props.createTeamSubmit(model)} 
                        onValid={() => props.enableButton()}
                        onInvalid={() => props.disableButton()}
                        >
        <Dialog className="CreateTeamFormDialog" style={{width: '90%'}} 
                //header={props.currentActivity ? props.currentActivity.title : "Loading.."} 
                header="Create Team"
                footer={footer}
                visible={props.show} 
                modal 
                onHide={() => props.hide(false)}
                >
                    {/* <p>{props.currentActivity ? props.currentActivity.description : "loading.."}</p> */}
                    
                

                    <FormsyElement 
                        name="name"
                        value={props.teamNameValue}
                        validations="minLength:3"
                        validationError="Too Short"
                        required>
                        <InputText
                            // name="username"
                            value={props.teamNameValue}     
                            onChange={(e) => props.setTeamNameHandler(e.target.value)}
                            placeholder="Team Name"
                            
                        />
                    </FormsyElement>
                    <p>{props.error}</p>
                    {/* <Button label="Create" type="submit" className="p-info" disabled={!props.canSubmit}/> */}
            
        </Dialog>
        </Formsy>
        
     );
}
 
export default CreateTeamForm;