import React, { useEffect, useState } from 'react';

import './CategoryHeader.css';
import superApi from '../../../../Api/Api';
const logos = require.context('../../../../assets/images/categories/', true);

const CategoryHeader = (props) => {

    let imgUrl = 'https://images.thewellbeingchallenge.co.nz/'
    let img = null;
    try {
        img = logos(`./${props.category.trim().toLowerCase()}.png`)
    } catch {
        // alert(props.category)
        img = logos('./fun.png')

    }

    let _points = props.points.map(point => point.points).reduce((a, b) => {

        return a + b
    }, 0)

    return (
        <div style={{ display: 'flex', flex: 1,width: '100%', justifyContent: 'space-between', backgroundColor: props.categories[props.category].color || "black" }} className="CategoryHeader">
            <div style={{ display: 'flex', flex: 1, width: '33.33%', justifyContent: 'flex-start'  }}>
                <span>{props.categories[props.category].slogan}</span>

            </div>
            <div style={{ display: 'flex', flex: 1,width: '33.33%', justifyContent: 'center'  }}>
                <img className="catImage" src={img} />

            </div>
            <div style={{ display: 'flex', flex: 1,width: '33.33%', justifyContent: 'flex-end'   }}>

                <span>
                    POINTS: {_points}
                </span>
            </div>
        </div>
    );
}


export default CategoryHeader;