import axios from "axios";
import * as Sentry from '@sentry/browser'

let baseURL = ''

switch (process.env.NODE_ENV) {
    case 'production':
        baseURL = 'https://twbcauth.websoftware.nz'
        break
    case 'development':
        baseURL = 'http://docker10.homelab.local:8191'
        break

    default:
        baseURL = 'http://docker3.websoftware.nz:8191'

}

export const getToken = () => {

    const token = window.localStorage.getItem('twbcToken')

    return token


}

export const logout = () => {
    Sentry.captureMessage(`Logged out`)
    window.localStorage.removeItem('twbcToken');
    window.localStorage.removeItem('authBody');
    window.localStorage.removeItem('auth');
    window.location.reload()
}
export const login = async (username, password) => {

    const handleSignInSuccess = (token) => {
        if (token) {
            window.localStorage.setItem('twbcToken', token)
        } else {
            alert('Something went wrong')
        }
    }

    const payload = {
        "applicationId": '51524625-4fd3-4d58-bb3f-9a17a5538606',
        "loginId": username,
        "password": password
    }


    const options = {
        method: 'POST',
        url: `${baseURL}/api/login`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };

    await axios.request(options).then(function (response) {
        if (response.data) {
            handleSignInSuccess(response.data.token)
        }
    }).catch(function (error) {
        if (error.response) {
            if (error.response.status === 404) {
                return Promise.reject('No such username, or wrong password')
            }
        }
        return Promise.reject('Login Fails')
    });


}

