import React, { useState, useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Activities from './Activities/Activities2';
import Nav from './Nav/Nav';
import Home from './Home/Home';
import Teams from './Teams/Teams';
import Profile from './Profile/Profile';
import Leaderboards from './Leaderboards/Leaderboards';
import History from './Profile/History/History';
import TitleBar from '../../components/TitleBar/TitleBar';
import { AuthContext } from '../../Context/AuthContext';
import NotFound from '../../components/NotFound/NotFound';
import Aux from '../../HOC/AuxAuth/AuxAuth';
import Share from '../../components/Share/Share';
import './Layout.css';
import Preferences from './Preferences/Preferences';
import Placeholder from './Placeholder/Placeholder';

const Layout2 = (props) => {
    const [totalPoints, setTotalPoints] = useState(0);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading...")
    const [showPlaceHolder, setShowPlaceHolder] = useState(null)
    const [daysRemaining, setDaysRemaining] = useState([0, 'days'])
    const { authenticated, setAuthenticated, profile } = useContext(AuthContext);

    const _date = new Date()
    const _timestamp = _date.getTime()

    useEffect(() => {

        if(profile.startdate) {
            let _days = (profile.startdate.$date-_timestamp)/(1000*60*60*24)
            let _text = "days"
            if(_days < 1) {
                _days = (_days*24).toFixed(0)
                _text = _days < 2 ? "hour" : "hours" 
            } else {
                _days = _days.toFixed(0)
                _text = _days < 2 ? "day" : "days"
            }

           
            setDaysRemaining([_days, _text])
            if((profile.startdate.$date - _timestamp) > 0) { // Challenge hasn't started
                setShowPlaceHolder(true)
            }
        }
    }, [profile])

    if(showPlaceHolder) {
        return (
            <Placeholder days={daysRemaining[0]} text={daysRemaining[1]} />
        )
    }
    return (
        //TODO: clean up redirect, rename Aux. Remove render ifs from routes.
        <Aux authenticated={authenticated} setAuthenticated={setAuthenticated}>
            {/* <TitleBar title={profile.company ? profile.company : "Wellbeing Challenge"} /> */}
            <div className="Layout">
                <Switch>
        
                    <Route path="/" exact render={(props) => (authenticated ? <Home loadingText={loadingText} setLoadingText={setLoadingText} share={() => setShowShareDialog(true)} totalPoints={totalPoints} route={props} /> :  <Redirect to="/login" />)} />
                    <Route path="/activities" render={(props) => (authenticated ? <Activities share={() => setShowShareDialog(true)} {...props} route={props} setTotalPoints={setTotalPoints} /> : <Redirect to="/login" />)} />
                    <Route path="/team" render={(props) => (authenticated ? <Teams share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/profile/history" render={(props) => (authenticated ? <History share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/profile" render={(props) => (authenticated ? <Profile share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/leaderboards" render={(props) => (authenticated ? <Leaderboards share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/preferences" exact render={(props) => (authenticated ? <Preferences loadingText={loadingText} setLoadingText={setLoadingText} share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/placeholder" render={Placeholder} />
                    <NotFound />
                </Switch>
            </div>
            <Nav />
                    <Share show={showShareDialog} hide={() => setShowShareDialog(false)} profile={profile}/>
                    
        </Aux>
    )
}

export default Layout2;