import React from 'react';
import { Card } from 'primereact/card';
import './Challenge.css';
import Activity from '../../containers/Layout/Activities/Activity/Activity'

const Challenge = (props) => {


    if (props.activity.category !== undefined) {
        const category = props.categories[props.activity.category]
        return (
            <div className="Challenge">
                {/* <div className=""> */}
                <div className="header" style={{ "backgroundColor": category.color }}>
                    <div style={{ display: 'flex' }} className="textWrapper">
                        <div className="Text">{category.slogan} - challenge!</div>
                        <div className="subText">{props.activity.title}</div>

                    </div>
                </div>
                {/* </div> */}

                <div style={{ "textAlign": "left", "backgroundColor": category.color }}  >
                    <Activity {...props} />
                </div>
            </div>
        );
    }

    return "Loading.."
}

export default Challenge;