export const levelSpriteMap = {
    1: -0,
    4: -340,
    7: -680,
    10: -1020,
    13: -1360
}

export const characterSelection = [
    {
        "name": "Skater dude",
        "description": "Life’s about having fun right? Anyone joining me at the skate park?",
        "filename": "teal"
        },
        {
        "name": "Curly" ,
        "description": "Work it! Being active is the name of my game",
        "filename": "purple"
        },
        {
        "name": "Chilly Jilly",
        "description": "Just chilling out",
        "filename": "orange" 
        },
        {
        "name": "Pinky",
        "description": "An apple a day keeps the doctor away",
        "filename": "magenta"
        },
        {
        "name": "Green fingers",
        "description": "Wholefood is soul food",
        "filename": "green"
        }
    // {
    //     "name": "Chopper",
    //     "description": "Likes cake, but not carrot cake. Carrots are for rabbits, not for cake.",
    //     "filename": "teal"
    // },
    // {
    //     "name": "Amanda",
    //     "description": "Nice, but dim. Not the sharpest tool in the shed, but has a heart of gold. Amanda likes to dig holes in the dirt and suck the dirt out of her fingernails",
    //     "filename": "purple"
    // },
    // {
    //     "name": "Pierce",
    //     "description": "Hates his name, and will attack anyone that uses it. Careful, he prefers 'Peatie'",
    //     "filename": "orange"
    // },
    // {
    //     "name": "Chewnah",
    //     "description": "Book worm, studying to become a micro biologist. Loves Trump.",
    //     "filename": "magenta"
    // },
    // {
    //     "name": "Minky",
    //     "description": "Minky classifies herself as non-binary, a massive feminist. Speaks 18 languages, most of which are made-up.",
    //     "filename": "green"
    // }


]