import React from 'react';
import './Setting.css';
import {InputSwitch} from 'primereact/inputswitch';

const Setting = (props) => {
    return (
        <div className="Setting">
            <div className="label">{props.label}</div>
            <InputSwitch onLabel="Yes" offLabel="No" checked={props.checked} onChange={(e) => props.change(e.value)} />
        </div>
    );
}

export default Setting;