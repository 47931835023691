import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import superApi from '../../../Api/Api';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { AuthContext } from '../../../Context/AuthContext';
import Leaderboard from './Leaderboard/Leaderboard';
import './Leaderboards.css';

const Leaderboards = (props) => {
    const auth = useContext(AuthContext);
    const [leaderboard, setLeaderboard] = useState({});
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState('individual');
    const [week, setWeek] = useState(0);
    const [leaderboardWeek, setLeaderboardWeek] = useState(0)
    // const [isLoading, setIsLoading] = useState(true)
    const [maxWeeks, setMaxWeeks] = useState(1)

    useEffect(() => {
        superApi.get('leaderboard/' + leaderboardWeek + '?' + new Date().getTime())
            .then(
                (response) => {
                    auth.setLeaderboard(response.data)
                    props.setIsLoading(false)
                    setMaxWeeks(response.data.num_weeks)
                }
            ).catch(error => {
                if (error.request) {
                    props.setLoadingText(error.message + " :(")

                }
            });




    }, [leaderboardWeek])

    function selectTeam() {
        setType('team');
    }
    function selectIndividual() {
        setType('individual');
    }
    function selectWeek() {
        const _weeks = Object.keys(leaderboard).map((key) => parseInt(key));
        const _lastweek = _weeks.reduce((a, b) => Math.max(a, b));
        setWeek(_lastweek);
    }
    function selectTotal() {
        setWeek(0);
    }

    let CONTENT = loading ? (
        <ProgressSpinner className="noLeaderboards" />
    ) : (
            <h4 className="noLeaderboards">"No Leaderboards yet!"</h4>
        );

    if (auth.leaderboard.leaderboard !== undefined) {
        CONTENT = (
            <div className="board">
                <div className="tab">
                    <button
                        className={type === 'team' ? 'active' : ''}
                        onClick={selectTeam}
                    >
                        Team
                    </button>
                    <button
                        className={type === 'individual' ? 'active' : ''}
                        onClick={selectIndividual}
                    >
                        Individual
                    </button>
                </div>

                {/* <div className="tab tab2">
                    <button
                        className={week === 0 ? '' : 'active'}
                        onClick={selectWeek}
                    >
                        Week
                    </button>
                    <button
                        className={week === 0 ? 'active' : ''}
                        onClick={selectTotal}
                    >
                        Total
                    </button>
                </div> */}

                <Leaderboard
                    profile={auth.profile}
                    type={type}
                    week={week}
                    leaderboards={auth.leaderboard}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            <TitleBar share={props.share} items={[]} />
            <div className="Leaderboards">
                <div className="heading-wrapper">
                    <div className="backButton">
                        {leaderboardWeek  >=0 ? <i style={{fontSize: "28px"}} className="pi pi-arrow-left" onClick={() => setLeaderboardWeek(leaderboardWeek - 1)}></i> : <i style={{color: "lightgrey", fontSize: "28px"}} className="pi pi-arrow-left"></i>}
                    </div>
                    <h2 className="leaderboard-text">{`Leaderboard`}</h2>
                    <div className="forwardButton">
                        {leaderboardWeek  <=-1 ? <i style={{fontSize: "28px"}} className="pi pi-arrow-right" onClick={() => setLeaderboardWeek(leaderboardWeek + 1)}></i> : <i style={{color: "lightgrey", fontSize: "28px"}} className="pi pi-arrow-right"></i>}
                    </div>
                </div>
                {CONTENT}
            </div>
        </React.Fragment>
    );
};

export default Leaderboards;
