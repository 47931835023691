import React from 'react';
import './Forgot.css';

const Forgot = (props) => {

    let displayClass = "hide"

    if (props.show) {
        displayClass = "show"
    }

    let text = ""
    let btnText = "submit"
    let btnClass = "p-button-info"

    switch(props.type) {
        case 'forgot':
            text = "Enter your email to recover your password"
            break
        case 'verify':
            text = "Enter verification code"
            btnText = "validate"
            btnClass = "p-button-warning"
            break
        default:
            break
    }

    return (
        <div className={"Forgot " + displayClass}>
            <span className="title">
                <a className="back" onClick={props.close}>
                    <i class="material-icons">
                        keyboard_arrow_down

                        </i>
                </a>


            </span>
            <div className="emailWrapper">
                <p className="recoverText">{text}</p>
                <input className="email" 
                        type="text" 
                        value={props.emailValue}
                        onChange={(e) => props.setEmailValue(e.target.value)}/>
                <div className="buttons">
                    <button disabled={props.disable} onClick={() => props.forgotSubmit(props.type)} className={btnClass}>submit</button>
                    {props.type == 'forgot' ? <button disabled={props.disable} onClick={() => props.setFormType('verify')} className="p-button-danger">enter code</button> 
                    : <button disabled={props.disable} onClick={() => props.setFormType('forgot')} className="p-button-danger">cancel</button>}
                    
                </div>
            </div>
        </div>
    );
}

export default Forgot;