import axios from 'axios';


const superApi = axios.create({


})

switch (process.env.NODE_ENV) {
    case 'production':
        superApi.defaults.baseURL = 'https://twbc2.websoftware.nz/'
        break
    case 'development':
        // superApi.defaults.baseURL = 'http://192.168.86.100:5000/'
        superApi.defaults.baseURL = 'http://docker10.homelab.local:8181/'

        break
    default:
        superApi.defaults.baseURL = 'http://localhost:5000/'

}

if (process.env.REACT_APP_TEST) {
    superApi.defaults.baseURL = 'https://api.thewellbeingchallenge.co.nz:4431/'
}

if (process.env.REACT_APP_LOCAL) {
    superApi.defaults.baseURL = 'http://localhost:5000/'
}

superApi.defaults.auth = {
    username: window.localStorage.getItem('authBody'),
    password: ''
}
superApi.interceptors.request.use(config => {
    const token = window.localStorage.getItem('authBody');
    if (token && token !== 'null') {
        config.auth = {
            username: token,
            password: ''
        }
    }

    return config;
})
superApi.interceptors.response.use((response) => {


    return response;
}, (error) => {

    if (error.response && error.response.status === 401) {
        window.localStorage.removeItem('auth');
        window.localStorage.setItem('expired', true);
        window.localStorage.removeItem('authBody');

    }

    return Promise.reject(error);

})

export default superApi;