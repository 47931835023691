import React, { useState, useEffect, useContext } from 'react';
import Formsy from 'formsy-react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormsyElement from '../../../HOC/FormsyElement/FormsyElement';
import { Password } from 'primereact/password';
import TitleBar from '../../../components/TitleBar/TitleBar';
import superApi from '../../../Api/Api';
import NotFound from '../../../components/NotFound/NotFound';
import { AuthContext } from '../../../Context/AuthContext';
import { Redirect, Link } from 'react-router-dom';
import './Register.css';
import AnimatedLogo from '../../../components/Overlay/AnimatedLogoNew/AnimatedLogo';
import { Checkbox } from 'primereact/checkbox';
import newLogo from '../../../assets/images/newLogo.png';

const Register = (props) => {

    const [validRoute, setValidRoute] = useState(false);
    const [company, setCompany] = useState({ company: 'Not Found' });
    const [checking, setChecking] = useState(true)
    const [acceptedTerms, setAcceptedTerms] = useState(false)

    const auth = useContext(AuthContext);

    const tz_offset = () => {
        const d = new Date();
        return d.getTimezoneOffset();
    }
    useEffect(() => {
        // Verify company slug
        superApi.get('/get_company?slug=' + props.match.params.company)
            .then((response) => {
                console.log(response.data)
                setValidRoute(true);
                setCompany(response.data);
                setChecking(false)
            })
            .catch((error) => {
                setValidRoute(false);
                setChecking(false)
            })
    }, [])

    if (checking) {
        return <p>Please wait</p>
    }
    if (validRoute && !auth.authenticated) {
        return (
            <React.Fragment>
                {/* <TitleBar items={false} title={company.company.company}/> */}
                <div className="Register">
                    <div className="logo-wrapper" style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        {/* <AnimatedLogo /> */}
                        <img style={{ width: '90%'}} src={newLogo} />
                    </div>
                    <div className="form-wrapper">
                        <Formsy onValidSubmit={(model) => props.auth.registerSubmit(model, auth)}
                            onValid={() => props.auth.enableButton()}
                            onInvalid={() => props.auth.disableButton()}>

                            <FormsyElement
                                name="username"
                                value={props.auth.userValue}
                                validations="isEmail"
                                validationError="Please enter a valid Email"
                                required>
                                <InputText
                                    value={props.auth.userValue}
                                    onChange={(e) => props.auth.setUserValueHandler(e.target.value)}
                                    placeholder="email@example.com"
                                    autoCapitalize="none"
                                />
                            </FormsyElement>

                            <FormsyElement
                                name="password"
                                value={props.auth.passValue}
                                validations="minLength:6"
                                validationError="Password too short"
                                required>
                                <Password
                                    value={props.auth.passValue}
                                    onChange={(e) => props.auth.setPassValueHandler(e.target.value)}
                                    placeholder="Password"
                                    feedback={false}
                                />
                            </FormsyElement>
                            <FormsyElement
                                name="password2"
                                value={props.auth.pass2Value}
                                validations={{
                                    matchesPasswordField: (values, value) => {
                                        return value === props.auth.passValue ? true : 'Password does not match';
                                    }
                                }}
                                validationError="Password does not match"
                                required>
                                <Password
                                    value={props.auth.pass2Value}
                                    onChange={(e) => props.auth.setPass2ValueHandler(e.target.value)}
                                    placeholder="Confirm Password"
                                    feedback={false}
                                />
                            </FormsyElement>
                            <FormsyElement
                                name="voucher"
                                value={props.auth.voucherValue}
                                validations={{
                                    publicCheck: (values, value) => {
                                        if (company.company.public == false) {
                                            return true
                                        }
                                        return value.length > 0 ? true : 'Voucher is required'
                                    }
                                }}
                                validationError="Min 8 characters"
                            >
                                <InputText
                                    value={props.auth.voucherValue}
                                    onChange={(e) => props.auth.setVoucherValueHandler(e.target.value)}
                                    placeholder="VOUCHER"

                                    feedback={false}
                                />
                            </FormsyElement>
                            <FormsyElement
                                name="terms"
                                value={acceptedTerms}
                                validations={{
                                    tickedBox: (values, value) => {
                                        return value === true ? true : 'Accept Terms and Conditions';
                                    }
                                }}
                                validationError=""
                                required>
                                <div className="acceptTermsWrapper">
                                    <label htmlFor="acceptTerms" >Accept <a target="_blank" href="http://www.thewellbeingchallenge.co.nz/terms-of-use.html">Terms</a></label>
                                    <Checkbox inputId="acceptTerms" id="acceptTerms" onChange={e => setAcceptedTerms(e.checked)} checked={acceptedTerms} />
                                </div>
                                </FormsyElement>



                            <FormsyElement
                                name="company"
                                value={props.match.params.company}>
                                <input type="hidden" name="company" value={props.match.params.company} />
                            </FormsyElement>
                            <FormsyElement
                                name="tz_offset"
                                value={tz_offset()}>
                                <input type="hidden" name="tz_offset" value={tz_offset()} />
                            </FormsyElement>
                            <Button label="sign up" type="submit" disabled={!props.auth.canSubmit} />
                            <div class="orLogIn"><a href="/login">Already signed up?</a></div>
                        </Formsy>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    else if (!auth.authenticated) {
        return (
            <NotFound />
        )
    }

    else {
        return <Redirect to="/" />
    }
}

export default Register;