import React, { useState, useRef, useEffect, useContext } from 'react';
import './Teams.css';

import superApi from '../../../Api/Api';
import {Toast} from 'primereact/toast';
import Team from './Team/Team';
import { AuthContext } from '../../../Context/AuthContext';
import MyTeamDetails from './MyTeamDetails/MyTeamDetails';
import CreateTeamForm from './CreateTeamForm/CreateTeamForm';
import { ReactComponent as NoGroup } from './no-group.svg';
import TeamsList from './TeamsList/TeamsList';

const Teams = (props) => {
    
    

    const [canSubmit, setCanSubmit] = useState(false);
    const [teamNameValue, setTeamNameValue] = useState('');
    const [showCreateTeamDialog, setShowCreateTeamDialog] = useState(false);    
    const [apiError, setApiError] = useState(null);
    const growlRef = useRef(null);
    const auth = useContext(AuthContext);

    useEffect(() => {
        if(apiError) {
            growlRef.current.show({severity: 'error', summary: 'Failed', detail: apiError});
            setApiError(null)
        }
    }, [apiError])

    const disableButton = () => {
        setCanSubmit(false);
    };
    const enableButton = () => {
        setCanSubmit(true);
    };

    const setTeamNameHandler = (value) => {
        setTeamNameValue(value);
    }

    const joinTeamHandler = (teamId) => {
        superApi.post('/myteam', {action: 'join', team: teamId.$oid})
        .then((response) => {
            auth.setMyTeam(response.data.team);
            auth.getProfile();
        })
    }

    const leaveTeamHandler = (teamId) => {
        if(window.confirm('Are you sure you want to leave the team?')) {
            superApi.post('/myteam', {action: 'leave', team: teamId.$oid})
            .then((response) => {
                auth.setMyTeam(response.data.team);
                auth.getProfile();
                auth.getMyTeam();
            })
        }
        
    }
    
    const createTeamSubmit = (model) => {
        setApiError(null);
        superApi.post('/teams', model)
        .then((response) => {
            setApiError(null);
            setShowCreateTeamDialog(false);
            setTeamNameValue("");
            let prevTeams = []
            prevTeams.push(response.data.team);
            auth.teams.map((team) => {
                prevTeams.push(team);
                return null;
            })
            // auth.setTeams(prevTeams);
            auth.setMyTeam(response.data.team);
            auth.getProfile();
            
        })
        .catch((error) => {
            setApiError("Try another name");
            return error;
            
        })
    }

    const TEAMS = auth.teams.map((team) => {
        console.log(team)
        return (
            
                <Team key={team.name} team={team} label="join" click={joinTeamHandler}/>
        )
        
    }
    )
    if(auth.myTeam != null) { // Are part of team
        
        return (
            <React.Fragment>
            <MyTeamDetails team={auth.myTeam} leaveTeam={leaveTeamHandler}/>
   
            </React.Fragment>
            )

        
    } else { // Not in team

    
    return ( 
        <React.Fragment>
            <Toast ref={(el) => growlRef.current = el} />
            {/* <h1>Teams</h1> */}
            <div className="NoTeam">
                <NoGroup />
                <span className="text">
                    You are not a member of any team.
                    Use the Add button to create or join one!
                </span>
                
                <div className="AddJoinPanel">
                    <div className="left-button">
                        <a className="btn-floating btn-large white" onClick={() => setShowCreateTeamDialog(true)}>
                            <i className="large material-icons">add</i>
                        </a>
                        <p>Create Team</p>
                    </div>
                    <div className="right-button">
                        <a className="btn-floating btn-large white" onClick={() => document.querySelector('.TeamsList').classList.remove('hide')}>
                            <i className="large material-icons">search</i>
                        </a>
                        <p>Join Team</p>
                    </div>
                </div>
            </div>
            <CreateTeamForm canSubmit={canSubmit}
                            createTeamSubmit={createTeamSubmit}
                            setTeamNameHandler={setTeamNameHandler}
                            disableButton={disableButton}
                            enableButton={enableButton}
                            show={showCreateTeamDialog}
                            hide={setShowCreateTeamDialog}
                            teamNameValue={teamNameValue}
                            joinTeam={joinTeamHandler}
                            
                            
                            />
            {/* <ul style={{listStyle: 'none', padding: '20px', marginBottom: '80px'}}>
            {TEAMS}
            </ul> */}

            <TeamsList teams={TEAMS} />
        
        </React.Fragment>
     );
    }
}
 
export default Teams;