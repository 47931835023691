import React from 'react';
import newLogo from '../../../assets/images/newLogo.png';
import header from '../../../assets/images/placeholder-header.png';
import './Placeholder.css';
const PlaceHolder = (props) => {
    return (
        <div className="Placeholder">
            <div className="section header">
                <img src={header} />
            </div>
            <div className="section main">
                <h5>Thanks for registering for The Wellbeing Challenge!</h5>
                <h5 className="bold">The challenge starts in</h5>
                <h2 className="days">{props.days} {props.text}</h2>
            </div>
            <div className="section text">
                <p>While you're waiting:</p>
                <ul>
                    <li>Think about your objective for The Wellbeing Challenge</li>
                    <li>Find Friends, workmates and family to join you - it will help to keep you on track!</li>
                    <li>Log back in on Monday and the activities will be ready to action.</li>
                </ul>
            </div>
            <div className="footer">
                <div className="logo">
                    <img src={newLogo} />
                </div>
            </div>
        </div>
    );
}

export default PlaceHolder;