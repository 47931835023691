import React, { useState, useEffect } from 'react';
import './App.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/nova-colored/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css'
import { Route } from 'react-router-dom';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Register from './containers/Auth/Register/Register';
import RegisterRedirect from './containers/Auth/RegisterRedirect/RegisterRedirect';
import Layout2 from './containers/Layout/Layout2';
import AuthContext from './Context/AuthContext';
import Login from './containers/Auth/Login/Login';
import { login } from './services/authService';
import superApi from './Api/Api';
import '../node_modules/primeflex/primeflex.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
const App = (props) => {


  const [userValue, setUserValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [pass2Value, setPass2Value] = useState('');
  const [voucherValue, setVoucherValue] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);


  const setUserValueHandler = (val) => {

    setUserValue(val);
  };

  const setPassValueHandler = (val) => {

    setPassValue(val);
  };

  const setPass2ValueHandler = (val) => {

    setPass2Value(val);
  };

  const setVoucherValueHandler = (val) => {
    setVoucherValue(val);
  }
  const disableButton = () => {
    setCanSubmit(false);
  };
  const enableButton = () => {
    setCanSubmit(true);
  };

  const registerSubmit = (model, auth) => {
    superApi.post('/register', model)
      .then((response) => {
        // receives token
        login(model.username, model.password)
          .then(() => {
            window.localStorage.setItem('auth', true)
            window.localStorage.setItem('authBody', response.data.token)
            Sentry.captureMessage("User "+model.username+ "Registered and logged in successfully")

          })
        auth.setAuthenticated(true);
        auth.setAuthBody(response.data.token);
        setIsRegistered(true);

      })
      .catch((error) => {
        console.log(error)
        let _error = ""
        if (error.response !== undefined) {

          if (error.response.data.message !== undefined) {
            _error = error.response.data.message

          } else {
            _error = "Email already exists or is invalid"
          }
        }
        alert(_error);
      })
  };

  const authForm = {
    userValue: userValue.toLowerCase(),
    passValue: passValue,
    pass2Value: pass2Value,
    voucherValue: voucherValue,
    canSubmit: canSubmit,
    setUserValueHandler: setUserValueHandler,
    setPassValueHandler: setPassValueHandler,
    setPass2ValueHandler: setPass2ValueHandler,
    setVoucherValueHandler: setVoucherValueHandler,
    disableButton: disableButton,
    enableButton: enableButton,
    registerSubmit: registerSubmit
  }

  Sentry.init({
    // dsn: "https://472c69b152e44cee9f23c36554bd458f@sentry.xlab.monster/1",
    dsn: "https://045b569ce99e4f18b677165cb5bb2cc3@sentry.xlab.monster/2",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });




  return (
    <AuthContext>
      <div className="App">

        {/* <LoginInput loginHandler={this.props.loginHandler} /> */}

        <BrowserRouter>
          <Switch>
            <Route path="/login" exact render={(props) => <Login {...props} auth={authForm} />} />
            <Route path="/register" exact component={RegisterRedirect} />
            <Route path="/register/:company" render={(props) => <Register {...props} isRegistered={isRegistered} setIsRegistered={setIsRegistered} auth={authForm} />} />

            <Route path="/" component={Layout2} />

          </Switch>
        </BrowserRouter>

      </div>
    </AuthContext>

  );
}


export default App;
