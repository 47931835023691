import axios from 'axios';
import { getToken, logout } from './authService';
import * as Sentry from '@sentry/browser'
const api = axios.create({

})

api.interceptors.request.use((config) => {
    config.headers = {
        "Authorization": `Bearer ${getToken()}`
    }
    return config
})

api.interceptors.response.use((response) => response, (error) => {
    const errorIncludes = (payload, _string) => {
        return payload.includes(_string)
    }
    if(error.response) {
        Sentry.captureException(error.response)
        if(error.response.data) {
            const err = error.response.data.error
            
            if(errorIncludes(err, 'JWTExpired')) {
                //send to sentry
                logout()
            }
            if(errorIncludes(err, 'JWSError')) {
                // logout()
                //send to sentry
                console.log(err)
            }
            else {
                // send to sentry
            }
        }
        else {
            Sentry.captureException(error)
        }

    }
})


switch (process.env.NODE_ENV) {
    case 'production':
        api.defaults.baseURL = 'https://twbcgql.websoftware.nz/api/rest/'
        // api.defaults.headers = {
        //     "Authorization": `Bearer ${token}`
        // }
        break
    case 'development':
        api.defaults.baseURL = 'http://docker10.homelab.local:8180/api/rest/'
        // api.defaults.headers = {
        //     "Authorization": `Bearer ${token}`
        // }
        break

    default:
        api.defaults.baseURL = 'http://docker10.homelab.local:8180/api/rest/'
        // api.defaults.headers = {
        //     "Authorization": `Bearer ${token}`
        // }

}

export const getActivityByMid = async (mid) => {
    return api.get('activity', {params: {mid: mid}})
   
    
}