import React, { useState, useEffect } from 'react';
import './ChangePassword.css';
import superApi from '../../../../Api/Api';

const ChangePassword = (props) => {

    const [oldPasswordValue, setOldPasswordValue] = useState('')
    const [newPasswordValue, setNewPasswordValue] = useState('')

    useEffect(() => {
        if (props.oldPassword != null) {
        setOldPasswordValue(props.oldPassword)
        }
    })
    const changePasswordSubmitHandler = () => {
        superApi.post('/change', {password: oldPasswordValue, new_password: newPasswordValue})
        .then((response) => {

            if(response.status == 200) {
                props.close()
            } else if(response.status == 403) {
                window.alert('Wrong verification code or password')
            }
        })
        .catch((response) => window.alert('Wrong verification code or password'))
    }
    let displayClass = "hide"

    if (props.show) {
        displayClass = "show"
    }
    
    return ( 
        <div className={"ChangePassword " + displayClass}>
            <span className="title">
                <a className="back" onClick={props.close}>
                    <i class="material-icons">
                        keyboard_arrow_down

                        </i>
                </a>


            </span>
            <div className="passwordWrapper">
                <p className="changeText">Enter new password</p>
                <input className="password" 
                        type="password" 
                        value={oldPasswordValue}
                        onChange={(e) => setOldPasswordValue(e.target.value)}
                        placeholder="Enter current password"/>
                <input className="password" 
                        type="password" 
                        value={newPasswordValue}
                        onChange={(e) => setNewPasswordValue(e.target.value)}
                        placeholder="Enter new password"/>
                <div className="buttons">
                    <button onClick={() => changePasswordSubmitHandler()} className="p-button-info">submit</button>
                    
                </div>
            </div>
        </div>
     );
}
 
export default ChangePassword;