import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { AuthContext } from '../../../Context/AuthContext';
import CategoryHeader from './CategoryHeader/CategoryHeader';
import Activity from './Activity/Activity';
import './Activities.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AchievementDialog from './AchievementDialog/AchievementDialogFull';
import superApi from '../../../Api/Api';
import ActivityHistory from './ActivityHistory/ActivityHistory';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Challenge from '../../../components/Challenge/Challenge';
import useMeasure from 'react-use-measure'
import TitleBar from '../../../components/TitleBar/TitleBar';
const Activities = (props) => {
    const auth = useContext(AuthContext);
    const [showActivityDialog, setShowActivityDialog] = useState(false);
    const [showEditActivityDialog, setShowEditActivityDialog] = useState(false);
    const [showActivityHelp, setShowActivityHelp] = useState(false);
    const [achievementValue, setAchievementValue] = useState('');
    const [viewDate, setViewDate] = useState(moment());
    const [canPrev, setCanPrev] = useState(true);
    const [canNext, setCanNext] = useState(true);
    const [loading, setLoading] = useState(true);
    const isToday = viewDate.isSame(moment(), 'day');
    const [currentActivity, setCurrentActivity] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [hasStarted, setHasStarted] = useState(false);
    const [reload, setReload] = useState(true);
    const [activityDescription, setActivityDescription] = useState(null);
    const [showActivityHistory, setShowActivityHistory] = useState(false);
    const [activityHistory, setActivityHistory] = useState([]);
    const [achievementEditDate, setAchievementEditDate] = useState(moment())
    const [pointId, setPointId] = useState(null);
    const [formError, setFormError] = useState({})
    const [challenge, setChallenge] = useState(null)

    const [ref, bounds] = useMeasure()

    const setAchievementValueHandler = (value) => {
        setAchievementValue(value);
    }

    const closeActivityHistoryHandler = () => {
        setShowActivityHistory(false)
    }
    const showEditActivityDialogHandler = (pointId = null) => {
        if (pointId != null) {
            setPointId(pointId)
        }
        setShowEditActivityDialog(true)
    }

    const updateAchievementHandler = (pointId) => {

        superApi.patch('/achievements', {
            "id": pointId,
            "notes": achievementValue,
        })
            .then((response) => {
                setShowEditActivityDialog(false);
                closeActivityHistoryHandler();
            })

    }


    const submitAchievementHandler = (e, today, value, input_type='text') => {
        e.preventDefault();
        const now = new Date()
        let payload = {
            task: currentActivity._id,
            day: today.toJSON(),
            number: 0
        }
        switch (input_type) {
            case 'text':
                if (achievementValue == "") {
                    setFormError({ "achievement": "Must not be blank" })
                    return false
                }
                if (achievementValue.length < 6) {
                    setFormError({ "achievement": "Too short" })
                    return false
                }
                payload['notes'] = achievementValue
                break
            case 'bool':
                if(!value) {
                    setFormError({ "achievement": "Tick the box if you completed it" })
                    return false
                }
                payload['notes'] = "Completed "+now.getTime().toString()
                
                break
            case 'number':
                if(isNaN(value)) {
                    setFormError({"achievement": "Must be a number"})
                    return false
                }
                payload['notes'] = "Completed "+now.getTime().toString()
                payload['number'] = value
                break
        }
        
        console.log(payload)

        setIsLoading(true)
        // const payload = {
        //     task: currentActivity._id,
        //     day: today.toJSON(),
        //     notes: achievementValue
        // }


        superApi.post('/achievements', payload)
            .then(response => {
                setShowActivityDialog(false);
                setAchievementValue("");
                auth.getProfile();
                auth.getMyTeam();
                setIsLoading(false)
                if (response.data.streak.streak && response.data.streak.streak > 1) {
                    toast.success("YAY " + response.data.streak.streak + " in a row!")
                    setShowActivityDialog(false);
                    setAchievementValue("");
                } else {
                    toast.success("Well Done!")
                }
                if (response.data.streak.bonus) {
                    toast("BONUS POINTS! +" + response.data.streak.bonus)

                }

                auth.getMyPoints();
            })
            .catch((error) => {
                if (error.response) {
                    toast.error("Error, please refresh window")
                } else if (error.request) {
                    toast.error("Error, please try again")
                } else {
                    toast.error(error.message)
                }
                setShowActivityDialog(false);
                setAchievementValue("");
                setIsLoading(false)
            })


    }


    useEffect(() => {
        const today = moment()
        const startDate = auth.profile.startdate ? moment(auth.profile.startdate.$date) : today
        setHasStarted(moment(startDate).isBefore(today));
        if (viewDate.isSame(today, 'day')) {
            setCanNext(false);
        } else {
            setCanNext(true);
        }
        const daysDiff = today.diff(startDate, 'days')
        const subDays = daysDiff % 7;

        if (auth.profile.startdate && viewDate.isSame(moment(today).subtract(subDays, 'days'), 'day')) {
            setCanPrev(false)
        } else {
            setCanPrev(true)
        }
    })

    useEffect(() => {
        if(!showActivityDialog) {
            setFormError({})
        }
    },[showActivityDialog])



    useEffect(() => {
        const getActivities = () => {
        }
        getActivities();
    }, [auth.activities])

    useEffect(() => {
        if (auth.activities !== null && auth.categories !== null) {
            auth.activities.sort((a, b) => {
                if (a.title > b.title) {
                    return 1
                }
                return -1
            })
            setLoading(false);
        }


    }, [auth.profile, auth.categories])

    const prevDayHandler = () => {
        let newDate = moment(viewDate).subtract(1, 'days');
        // console.log(newDate)
        setViewDate(newDate);
    }
    const nextDayHandler = () => {

        let newDate = moment(viewDate).add(1, 'days');
        setViewDate(newDate);
    }
    const completeChallengeHandler = (challenge) => {
        if (!hasStarted) {
            window.alert("Challenge hasn't started")
            return false;
        }
        let _confirm = true
        if (!viewDate.isSame(moment(), 'day')) {
            _confirm = window.confirm('This is not today\s date!')
        }
        if (!_confirm) {
            return false;
        }

        setAchievementValue('');
        setShowActivityDialog(true);
        setCurrentActivity(challenge);
        if (auth.profile.preferences != undefined) {

            if (auth.profile.preferences.enable_auto_fill) {
                const _value = auth.points.filter(point => point.activity == challenge._id).slice(-1)[0]
                try {
                    setAchievementValue(_value.notes)
                }
                catch (e) {
                    setAchievementValue("")
                }

            }
        }


    }

    const showActivityHelpHandler = (helpText) => {
        setShowActivityHelp(true);
        setActivityDescription(helpText);
    }

    const showActivityHistoryHandler = (activity) => {
        // return
        setCurrentActivity(activity); //TODO: Regression testing
        setShowActivityDialog(false);
        setShowActivityHistory(true);

        superApi.get('/achievements', { params: { "date": viewDate, "task": activity._id, "tzoffset": viewDate.utcOffset() } })
            .then((response) => {
                setActivityHistory(response.data._items);
            })
    }

    const extractUrls = (helpText) => {
        const regEx = /({{.*?}})/g
        const vars = helpText.match(regEx)
        if (vars != null) {

            let urls = []

            for (let url of vars) {
                const split = url.replace('{{', "").replace('}}', "").split(',')
                const hyperlink = `<a href="${split[1]}" target="_blank">${split[0]}</a>`
                urls.push({ 'var': url, 'url': hyperlink })
            }
            for (let i in Object.entries(urls)) {
                helpText = helpText.replace(urls[i].var, urls[i].url)
            }
        }

        return helpText;
    }
    let ACTIVITIES = []

    if (!loading) {
        let _lastCat = null

        ACTIVITIES = auth.activities.sort((a, b) => a.category > b.category ? -1 : 1).map(activity => {
            if (_lastCat === null) {
                // First

            }

            if (activity.type === 'challenge') {
                if (challenge === null) {
                    const _points = auth.points.filter(point => point.activity == activity._id)
                    setChallenge(activity)
                }

                return false;
            }

            if (activity.type === 'weekly') {
                // don't display if already done
                    // TODO: this doesn't reset at end of week
                const _points = auth.points.filter(point => point.activity == activity._id)
                const _hasPoints = _points.length > 0
                if (_hasPoints) {
                    return false;
                }

            }
            if (_lastCat != activity.category) {
                // New Category Header
                _lastCat = activity.category
                return (
                    <React.Fragment key={activity._id}>

                        <CategoryHeader
                            key={activity.category}
                            category={activity.category}
                            categories={auth.categories}
                            points={auth.points.filter(point => point.category == activity.category && moment(viewDate).isSame(moment(point.day), 'day'))}
                        />
                        <Activity
                            key={activity._id}
                            stats={[]}
                            points={auth.points.filter(point => point.activity == activity._id && moment(viewDate).isSame(moment(point.day), 'day'))}
                            offset={auth.profile.tz_offset}
                            key={activity.title}
                            activity={activity}
                            today={viewDate}
                            completeChallenge={completeChallengeHandler}
                            showChallenge={() => console.log('show Challenge')}
                            buttonClick={completeChallengeHandler}
                            showHelp={showActivityHelpHandler}
                            showHistory={showActivityHistoryHandler}
                            extractUrls={extractUrls}
                        />
                    </React.Fragment>
                )

            } else {
                // append activity
                _lastCat = activity.category
                return (
                    <Activity
                        key={activity._id}
                        stats={[]}
                        points={auth.points.filter(point => point.activity == activity._id && moment(viewDate).isSame(moment(point.day), 'day'))}
                        offset={auth.profile.tz_offset}
                        key={activity.title}
                        activity={activity}
                        today={viewDate}
                        completeChallenge={completeChallengeHandler}
                        showChallenge={() => console.log('show Challenge')}
                        buttonClick={completeChallengeHandler}
                        showHelp={showActivityHelpHandler}
                        showHistory={showActivityHistoryHandler}
                        extractUrls={extractUrls}
                    />
                )
            }
        })


    }

    return (
        <>
            <TitleBar share={props.share} items={[]} />
            <div className="Activities">
                <ToastContainer position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover />
                <div className="dateSelect-wrapper">
                    <Button style={{ borderRadius: '50%', backgroundColor: '#e0e0e0' }} disabled={!canPrev} icon="pi pi-arrow-left" onClick={prevDayHandler} />
                    <div className={isToday ? "isToday" : ""}>{viewDate && !isToday ? viewDate.format('dd D MMM') : isToday ? "Today" : "Loading..."}</div>
                    <Button style={{ borderRadius: '50%', backgroundColor: '#e0e0e0' }} disabled={!canNext} icon="pi pi-arrow-right" onClick={nextDayHandler} />
                </div>
                {challenge != null ? <Challenge

                    stats={[]}
                    points={auth.points.filter(point => point.activity == challenge._id && moment(viewDate).isSame(moment(point.day), 'day'))}
                    offset={auth.profile.tz_offset}
                    key={challenge.title}
                    activity={challenge}
                    today={viewDate}
                    categories={auth.categories}
                    completeChallenge={completeChallengeHandler}
                    showChallenge={() => console.log('show Challenge')}
                    buttonClick={completeChallengeHandler}
                    showHelp={showActivityHelpHandler}
                    showHistory={showActivityHistoryHandler}
                    extractUrls={extractUrls}
                ></Challenge> : null}
                <ul style={{ listStyle: 'none', padding: '0', marginBottom: '80px' }}>

                    {ACTIVITIES}
                </ul>

                {currentActivity && <AchievementDialog activity={currentActivity}
                    setValue={setAchievementValueHandler}
                    submitAchievementHandler={submitAchievementHandler}
                    showActivityDialog={showActivityDialog}
                    setShowActivityDialog={setShowActivityDialog}
                    achievementValue={achievementValue}
                    showHistory={showActivityHistoryHandler}
                    today={viewDate}
                    type="add"
                    setIsLoading={setIsLoading}
                    errors={formError}
                    enablePhotoUploads={auth.profile.preferences ? auth.profile.preferences.enable_photo_upload : "FALSE"}
                />}

                {/* Edit Dialog */}
                <AchievementDialog activity={currentActivity}
                    setValue={setAchievementValueHandler}
                    submitAchievementHandler={updateAchievementHandler}
                    showActivityDialog={showEditActivityDialog}
                    setShowActivityDialog={setShowEditActivityDialog}
                    achievementValue={achievementValue}
                    today={achievementEditDate}
                    type="edit"
                    pointId={pointId}
                    errors={formError}
                    setIsLoading={setIsLoading}
                />

                <Dialog header="More Info" visible={showActivityHelp} modal onHide={() => setShowActivityHelp(false)}>
                    {activityDescription != null ? activityDescription.props.children : null}
                </Dialog>

                <ActivityHistory activity={currentActivity}
                    show={showActivityHistory}
                    close={closeActivityHistoryHandler}
                    history={activityHistory}
                    click={showEditActivityDialogHandler}
                    setNote={setAchievementValue}
                />
            </div>
        </>
    );
}

export default Activities;