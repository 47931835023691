import React from 'react';
import './TeamMember.css';
import superApi from '../../../../../Api/Api';

const TeamMember = (props) => {

    return ( 
        <li className="TeamMember">
        <span className="avatar-thumb">
            {props.member.profile.avatar ? <img src={superApi.defaults.baseURL +"avatar/"+ props.member.profile.avatar} /> :
            <i className="material-icons">{props.captain ? "perm_identity" : "person"}</i>}
        </span>
            
            <span className="name">{props.member.profile.first_name || props.member.profile.email}</span>
            <span className="points"><span>{props.points}</span></span></li>
     );
}
 
export default TeamMember;