import React, { useEffect, useState, useContext } from 'react';
import Loader from '../../../HOC/Loader/Loader';
import superApi from '../../../Api/Api';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Setting from './Setting/Setting';
import { AuthContext } from '../../../Context/AuthContext';
import './Preferences.css';
import SwipeableViews from 'react-swipeable-views';
import { characterSelection } from '../../../helpers/sprites';

const Preferences = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [enablePhotosValue, setEnablePhotosValue] = useState(true)
    const [enablePrivacyValue, setEnablePrivacyValue] = useState(false)
    const [enableAutoFill, setEnableAutoFill] = useState(false)
    const [enableLevels, setEnableLevels] = useState(false)
    const [characterIndex, setCharacterIndex] = useState(1)

    const auth = useContext(AuthContext)
    useEffect(() => {
        superApi.get('preferences').then(response => {
            setEnablePhotosValue(response.data.enable_photo_upload)
            setEnablePrivacyValue(response.data.enable_privacy)
            setEnableAutoFill(response.data.enable_auto_fill)
            setEnableLevels(response.data.enable_levels)
            setCharacterIndex(response.data.character_index)
            setIsLoading(false)
        }).catch(error => {
            if (error.response) {
                props.setLoadingText("Not implemented")
                // props.setLoadingText(error.response.status+" :(")
            }
            else if (error.request) {
                props.setLoadingText(error.message + " :(")

            }
        })

    }, [])

    useEffect(() => {
        handleCharacterSelect(characterIndex)
    },[characterIndex])

    const enablePhotosHandler = (val) => {
        superApi.post('preferences', { 'enable_photo_upload': val }).then(response => {
            setEnablePhotosValue(val)
            auth.getProfile()
        })
    }

    const enablePrivacyHandler = (val) => {
        superApi.post('preferences', { 'enable_privacy': val }).then(response => {
            setEnablePrivacyValue(val)
            auth.getProfile()
        })
    }

    const enableAutoFillHandler = (val) => {
        superApi.post('preferences', { 'enable_auto_fill': val }).then(response => {
            setEnableAutoFill(val)
            auth.getProfile()
        })
    }

    const enableLevelHandler = (val) => {
        superApi.post('preferences', { 'enable_levels': val }).then(response => {
            setEnableLevels(val)
            auth.getProfile()
        })
    }

    const handleCharacterSelect = (val) => {
        // Update character indexx
        superApi.post('preferences', { 'character_index': val }).then(response => {
            auth.getProfile()
        })
    }

    const changeSlide = (direction) => {
        const index_count = characterSelection.length
            switch(direction) {
                case 'left':
                    setCharacterIndex(prev => prev - 1)
                    break;
                case 'right':
                    setCharacterIndex(prev => prev + 1)

            } 
    }


    const CHARACTERS = characterSelection.map((character, i) => {
        return (
            <div key={i} className='w-full h-full flex align-items-center'>
                <div onClick={() => changeSlide('left')} className={(i > 0 && "text-2xl pi pi-chevron-left")}></div>
                <div className='col max-h-full overflow-hidden'>

                    <img style={{ width: '80%' }} src={`/assets/characters/preview/${character.filename}.png`} />
                </div>
                <div className='col flex flex-column align-items-start pl-1 text-xs justify-content-start  max-h-full overflow-scroll'>

                    {/* <div className=" flex align-items-start"><span className="font-bold">Name:</span></div> */}
                    <div className='font-bold text-lg'>{character.name}</div>
                    <br />
                    <div className="flex align-items-start"><span className="font-bold">About:</span></div>
                    <div className='text-left'>{character.description}</div>
                </div>
                <div onClick={() => changeSlide('right')} className={(i < characterSelection.length - 1 && "text-2xl pi pi-chevron-right")}></div>
            </div>
        )
    })
    return (
        <Loader text={props.loadingText} loading={isLoading}>
            <TitleBar share={props.share} items={[]} />
            <div className="Preferences">
                {/* <Setting tooltip="Prompt to upload a photo when entering achievement" label="Enable Photo Uploads" checked={enablePhotosValue} change={enablePhotosHandler} /> */}
                <Setting tooltip="Enable this if you don't want to share your achievements with your colleagues" label="Privacy Mode" checked={enablePrivacyValue} change={enablePrivacyHandler} />
                <Setting tooltip="Enable this to prepopulate the acheivement dialog with the last thing you wrote" label="Enable Auto Fill" checked={enableAutoFill} change={enableAutoFillHandler} />

                <Setting tooltip="Your chosen characters mood will change as you progress" label="Enable Levels" checked={enableLevels} change={enableLevelHandler} />
                <div className={(!enableLevels ? 'hidden' : '')}>
                    <hr />
                    <p className='text-left m-3 uppercase font-bold'>Choose your character</p>
                    <SwipeableViews index={characterIndex} enableMouseEvents resistance onChangeIndex={(index, latest, meta) => handleCharacterSelect(index, latest, meta)} >
                        {CHARACTERS}
                    </SwipeableViews>
                </div>
            </div>
        </Loader>
    );
}

export default Preferences;