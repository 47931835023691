import React, { useEffect, useState } from 'react';
import './Activity.css';
import moment from 'moment';
import ActivityHistory from '../ActivityHistory/ActivityHistory';
const Activity = (props) => {
	let todaysPoints = 0;

	const today = props.today;
	const isChallenge = props.activity.type === 'challenge';
	const isDaily = props.activity.type === 'daily';
	const isWeekly = props.activity.type === 'weekly';

	const helpText = (<div>{props.extractUrls(props.activity.description)}</div>)

	const URL = props.activity.url != "#" ? <a className="url" href={"http://" + props.activity.url} target="_blank" >Check it out</a> : null
	if (props.activity.hidden) {
		return null
	}
	let _points = 0
	if (props.points !== undefined) {

		_points = props.points.map(point => point.points).reduce((a, b) => a + b, 0)
	}

	const RadioUnchecked = () => (

		<div style={{
			height: '40px',
			width: '40px',
			borderRadius: '50%',
			border: 'thin solid #000'
		}}>

		</div>
	)

	const RadioChecked = () => (

		<div style={{
			height: '40px',
			width: '40px',
			borderRadius: '50%',
			border: 'thin solid #000',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<div style={{
				height: '30px',
				width: '30px',
				borderRadius: '50%',
				backgroundColor: '#000',
				border: 'thin solid #000',
				margin: 'auto',

			}}></div>

		</div>
	)

	const ChallengeLinks = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				<a onClick={() => props.showHelp(helpText)}>
					<span className="LearnMore cursor-pointer">More info</span>
				</a>
				&nbsp;&nbsp;&nbsp;&nbsp;
				{URL}
			</div>


		)
	}

	const ActivityEntryButton = () => <button className="promptLink" onClick={(e) => props.completeChallenge(props.activity)}><span>What did you do?</span><i className="material-icons checkbox">edit</i></button>
	
	switch (props.activity.type) {
		case 'weekly':

			return (
				<li className={props.stats ? "Activity challenge complete" : "Activity challenge"}>
					<div className="text">
					<span className="description">{props.activity.title}</span>
						<ChallengeLinks />

						{_points > 0 ? <span className="challengeCompleteText">Well Done!</span> : <span className="action"><ActivityEntryButton />
						</span>}
					</div>

					<div className="points">
						<span>{_points > 0 ? <a onClick={() => props.showHistory(props.activity)}><RadioChecked /></a> : <a onClick={() => props.showHistory(props.activity)}></a>}</span>
					</div>


				</li>
			)

		case 'challenge':

			return (
				<li className={props.stats ? "Activity challenge complete" : "Activity challenge"}>
					<div className="text">

						{/* <span className="description">{props.activity.title}<i style={{ color: 'orange' }} className="pi pi-star"></i><a onClick={() => props.showHelp(helpText)}><i className="material-icons checkbox help">chat</i></a>{URL}</span> */}
						<ChallengeLinks />
						{_points > 0 ? <span className="challengeCompleteText"></span> : <span className="action"><ActivityEntryButton />
						</span>}
					</div>

					<div className="points">
						<span>{_points > 0 ? <a onClick={() => props.showHistory(props.activity)}><RadioChecked /></a> : <a onClick={() => props.showHistory(props.activity)}></a>}</span>
					</div>


				</li>
			)

		case 'daily':

			return (
				<li className={props.stats ? "Activity daily complete" : "Activity daily"}>
					<div className="text">
						<span className="description">{props.activity.title}</span>
						<ChallengeLinks />
						{_points > 0 ? <span className="challengeCompleteText">Well Done!</span> : <span className="action"><ActivityEntryButton />
						</span>}
					</div>

					<div className="points">
						{/* <a onClick={() => props.showHistory(props.activity)}>{todaysPoints}</a> */}
						<span>{_points > 0 ? <a onClick={() => props.showHistory(props.activity)}><RadioChecked /></a> : <a onClick={(e) => props.completeChallenge(props.activity)}><RadioUnchecked /></a>}</span>
					</div>
				</li>
			)

		default:

			return (
				<li className={props.stats ? "Activity complete" : "Activity"}>
					<div className="text">
						<span className="description">{props.activity.title}</span>
						<span className="action"><ActivityEntryButton /></span>
					</div>

					<div className="points">
						<a onClick={() => props.showHistory(props.activity)}>{_points}</a>
					</div>
				</li>
			)

	}


}

export default Activity;