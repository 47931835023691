import React, { useState } from 'react';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import './Team.css';

import flag_au from '../../../../assets/images/au.png';
import flag_nz from '../../../../assets/images/nz.png';
import flag_uk from '../../../../assets/images/uk.png';
import flag_sg from '../../../../assets/images/sg.png';

const Team = (props) => {

    const [finished, setFinished] = useState(true)
    
    const flagMap = {
        "720": flag_nz,
        "600": flag_au,
        "60": flag_uk,
        "0": flag_uk,
        "480": flag_sg,
        "Unknown": flag_nz
    }
    const MEMBERS = props.team.members.map(member => <li className="member"><img class="flag" src={flagMap[member.country]} /><span>{member.name }</span></li>)
    if(finished) {
        return (
            <div className="Team">
                <Panel className="teamPanel" header={props.team.name} toggleable collapsed>
                    <ul>{MEMBERS}</ul>
                    <Button className="p-button-info" label={props.label} onClick={(e) => props.click(props.team._id)}/>
                </Panel>
            </div>
        )

    } else {
        return 'Loading...'
    }

}
 
export default Team;