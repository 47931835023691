import React from 'react';
import './History.css';
import TitleBar from '../../../../components/TitleBar/TitleBar';

const items = [{label: 'My History', icon: 'pi pi-fw pi-power-off',command:() => {window.location="profile/history" } }]
const History = (props) => {
    
    return ( 
        <TitleBar  title="My History" items={items} />
        
     );
}
 
export default History;